<template>
  <b-sidebar id="add-new-categoria-patrocinadores-sidebar" :visible="isEditSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm" @change="(val) => $emit('update:is-add-new-sidebar-active', val)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">Editar patrocinador</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
      </div>
      <div class="p-2">
        <canvas id="canvas" width="306.11" height="396" style="border-radius: 5.831px; border: 1px solid black;"></canvas>
        <!--
        <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="primary"
            @click="downloadImage"
          >
            Download da Imagem
          </b-button>
          -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="primary"
            @click="printImage"
          >
            Imprimir Imagem
          </b-button>
          
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            class="m-1"
            variant="danger"
            @click="hide"
          >
            Cancelar
          </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip,} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend  } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from 'vee-validate/dist/rules'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    participante: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        id: null,
        name: null,
        email: null,
        cpf: null,
        secretaria: null,
        crm: null,
        crm_uf: null
      },
      previewUrl: null
    }
  },
  methods: {
    printImage() {
      // Obtém o elemento canvas
      const canvas = document.getElementById('canvas');
      
      // Cria uma nova imagem usando o conteúdo do canvas
      const dataURL = canvas.toDataURL();

      // Cria um novo elemento de imagem
      const img = new Image();
      img.src = dataURL;

      // Cria uma nova janela para exibir a imagem em tamanho real
      const printWindow = window.open('', '_blank');
      printWindow.document.write(img.outerHTML);
      
      // Aguarda a imagem ser carregada antes de chamar a função de impressão
      img.onload = function() {
        printWindow.print();
        printWindow.close();
      };
    },
    generateImage() {
      // Obtém o elemento canvas
      const canvas = document.getElementById('canvas');
      const ctx = canvas.getContext('2d');

      // Define as cores e estilos
      const backgroundColor = '#D9D9D9';
      const circleColor = '#161D31';
      const textColor = '#161D31';
      const fontSize = 18; // Novo tamanho da fonte em pixels
      const fontSettings = `${fontSize}px Montserrat, sans-serif`;
      const logoSize = 112.969;
      const circleMarginTop = 43.73;
      const textMarginTop = 80; // Ajustando o espaçamento entre a logo e o texto "Gabriel Manoel Henry da Mata"
      const textMarginSide = 20; // Espaçamento entre os textos e a lateral do crachá
      const lineHeight = 40; // Ajustando o espaçamento entre os textos "Secretaria: Saúde" e "Cargo: Jornalista"
      const maxNomeLength = 26; // Limite máximo de caracteres para o nome

      // Limpa o canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Desenha o fundo do crachá
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Desenha o círculo para a logo
      ctx.fillStyle = circleColor;
      const circleX = (canvas.width - logoSize) / 2;
      const circleY = circleMarginTop;
      ctx.beginPath();
      ctx.arc(circleX + logoSize / 2, circleY + logoSize / 2, logoSize / 2, 0, 2 * Math.PI);
      ctx.fill();

      // Define o tamanho da fonte e espaçamento para o primeiro texto "Gabriel Manoel Henry da Mata"
      ctx.font = fontSettings;

      // Nome a ser exibido
      const nomeCompleto = this.form.name;

        // Verifica se o nome excede o limite máximo de caracteres
  if (nomeCompleto.length > maxNomeLength) {
    let indexQuebra = maxNomeLength;
    while (nomeCompleto[indexQuebra] !== ' ' && indexQuebra > 0) {
      indexQuebra--;
    }
    const linha1 = nomeCompleto.substring(0, indexQuebra);
    const linha2 = nomeCompleto.substring(indexQuebra + 1);

    // Desenha o texto da primeira linha
    const linha1Width = ctx.measureText(linha1).width;
    ctx.fillStyle = textColor;
    ctx.fillText(linha1, (canvas.width - linha1Width) / 2, circleY + logoSize + textMarginTop - 30);

    // Desenha o texto da segunda linha
    const linha2Width = ctx.measureText(linha2).width;
    ctx.fillText(linha2, (canvas.width - linha2Width) / 2, circleY + logoSize + textMarginTop + fontSize - 25);
  } else {
    // Desenha o texto completo em uma única linha
    const nomeWidth = ctx.measureText(nomeCompleto).width;
    ctx.fillStyle = textColor;
    ctx.fillText(nomeCompleto, (canvas.width - nomeWidth) / 2, circleY + logoSize + textMarginTop);
  }

      // Desenha o texto "Gabriel Manoel Henry da Mata" (ou os primeiros 12 caracteres do nome)
      

      // Ajusta o tamanho da fonte para os textos restantes "Secretaria: Saúde" e "Cargo: Jornalista"
      const smallerFontSize = 18; // Novo tamanho da fonte em pixels para os textos restantes
      const smallerFontSettings = `${smallerFontSize}px Montserrat, sans-serif`;
      ctx.font = smallerFontSettings;

      // Desenha o texto "Secretaria: Saúde"
      if(this.data.secretaria !== null){
        const secretaria = 'Secretaria: ' + this.data.secretaria;
        ctx.fillText(secretaria, textMarginSide, circleY + logoSize + textMarginTop + lineHeight + 10);
      }

      if(this.data.secretaria !== null){
        const cargo = 'Cargo: ' + this.data.cargo;
        ctx.fillText(cargo, textMarginSide, circleY + logoSize + textMarginTop + 20 + lineHeight * 2);
      }

      // Desenha o texto "Cargo: Jornalista"
      
    },
    downloadImage() {
      // Obtém o elemento canvas
      const canvas = document.getElementById('canvas');

      // Cria um link temporário para fazer o download da imagem
      const link = document.createElement('a');
      link.download = 'imagem_gerada.png';
      link.href = canvas.toDataURL();
      link.click();
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        // Cria uma instância FileReader
        const reader = new FileReader();

        // Define a função que será chamada após a leitura do arquivo
        reader.onload = (e) => {
          this.previewUrl = e.target.result; // Define a URL da prévia da imagem
        };

        // Lê o arquivo como uma URL de objeto (blob URL)
        reader.readAsDataURL(file);
      } else {
        this.previewUrl = null; // Se nenhum arquivo for selecionado, redefine a prévia como nula
      }
    },
    resetForm() {
      this.form = {
        id: null,
        name: null,
        email: null,
        cpf: null,
        secretaria: null,
        cargo: null,
        crm: null,
        crm_ud: null,
      }
      this.logo_old = null,
      this.$emit('close')
    },
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            const formData = new FormData()
            formData.append('id', this.form.id)
            formData.append('name', this.form.name)
            formData.append('cpf', this.form.cpf)
            formData.append('email', this.form.email)
            formData.append('secretaria', this.form.secretaria)
            formData.append('cargo', this.form.cargo)
            formData.append('crm', this.form.crm)
            formData.append('crm_uf', this.form.crm_uf)

            axios.post(`${process.env.VUE_APP_API}/events/update-participant/${this.form.id}`, formData)
              .then(response => {
                console.log(response.data)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sucesso ao atualizar!',
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.$emit('onSaved', true)
                this.resetForm()
                this.isEditSidebarActive = false
              })
              .catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: error.message,
                    variant: 'danger',
                  }
                })
              })

            
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
  },
  mounted() {
    console.log("Data")
    console.log(this.data)
    this.form.id = this.data.id
    this.form.name = this.data.name
    this.form.cpf = this.data.cpf
    this.form.email = this.data.email
    this.form.secretaria = this.data.secretaria
    this.form.cargo = this.data.cargo
    this.form.crm = this.data.crm
    this.form.crm_uf = this.data.crm_uf
    this.generateImage()
    console.log(this.category)
  }
}

</script>

<style>

</style>